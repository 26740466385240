<template>
  <div>
    <v-container fluid grid-list-lg class="p-1 mt-4 mb-4" v-if="!isLoading">
      <v-layout wrap>
        <!--Non mobile stats view-->
        <template v-if="!isMobile">
          <v-flex lg4 md6 xs12>
            <stat-widget
              icon="fal fa-hand-point-up"
              :title="currentDayData.clicks | local_numbers"
              subtitle="Clicks"
            ></stat-widget>
          </v-flex>
          <v-flex lg4 md6 xs12>
            <stat-widget
              icon="fal fa-fingerprint"
              :title="currentDayData.unique_clicks | local_numbers"
              subtitle="Unique clicks"
            ></stat-widget>
          </v-flex>
          <v-flex lg4 md6 xs12>
            <stat-widget
              icon="fal fa-shopping-basket"
              :title="currentDayData.sales | local_numbers"
              subtitle="Sales"
            ></stat-widget>
          </v-flex>
          <v-flex lg6 md6 xs12>
            <stat-widget
              icon="fal fa-money-bill-wave"
              :title="currentDayData.commission | local_numbers"
              subtitle="Commission"
            ></stat-widget>
          </v-flex>
          <v-flex lg6 offset-lg0 md6 offset-md3 xs12>
            <stat-widget
              icon="fal fa-percent"
              :title="currentDayData.conversion | local_numbers('%')"
              subtitle="Conversions"
            ></stat-widget>
          </v-flex>
        </template>
        <!--Mobile stats view-->
        <v-flex v-if="isMobile" xs12>
          <v-btn
            color="primary"
            outlined
            block
            class="mb-4"
            :to="{ name: 'tracking_links' }"
          >
            <v-icon left>fal fa-rocket</v-icon> Promote campaign
          </v-btn>

          <v-card>
            <v-card-title class="pb-0">
              <div>
                <div class="headline">Performance today</div>
                <div class="subtitle-1 light-grey">
                  <a href="https://intercom.help/Make-influence-com/da/articles/6248362-virksomhed-forsta-dine-rapporter" target="_blank">
                    Read more
                  </a>
                  about what these numbers are
                </div>
              </div>
            </v-card-title>
            <v-list two-line>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-hand-point-up</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ currentDayData.clicks | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Click</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-fingerprint</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ currentDayData.unique_clicks | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Unique clicks</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-shopping-basket</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ currentDayData.sales | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Sales</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-percent</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ currentDayData.conversion | local_numbers }}%
                  </v-list-item-title>
                  <v-list-item-title>Conversion rate</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-money-bill-wave</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ currentDayData.commission | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Commission</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>
        <v-flex v-if="hasInstagram" sm12 xs12>
          <v-card>
            <v-card-title class="pb-0">
              <div>
                <div class="headline">Instagram today</div>
                <div class="subtitle-1 light-grey">
                  Read about
                  <a href="https://intercom.help/Make-influence-com/da/articles/6194780-hvad-viser-instagram-i-dag-oversigten" target="_blank">
                    Make Influence posts
                  </a>
                </div>
              </div>
            </v-card-title>
            <v-list two-line>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-photo-video</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ instagramStats.posts | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Feed/Story posts</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-bullseye</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ instagramStats.reach | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Reach</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-eye</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ instagramStats.impressions | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Impressions</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text small color="accent" :to="{ name: 'report.instagram' }">
                See report <v-icon color="accent" right>fal fa-angle-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex v-if="hasTiktok" sm12 xs12>
          <v-card>
            <v-card-title class="pb-0">
              <div>
                <div class="headline">Tiktok overview</div>
              </div>
            </v-card-title>
            <v-list two-line>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-users</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ tiktokChannel.followers | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Followers</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-images</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ tiktokChannel.media_count | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Feed posts</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-heart</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ tiktokChannel.total_likes | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Likes</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>
        <v-flex sm6 xs12>
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Commission overview</div>
                <div class="subtitle-1 light-grey">
                  Read more about <a href="https://intercom.help/Make-influence-com/da/articles/7890737-forsta-vores-payout-flow" target="_blank">the payout flow</a>
                </div>
              </div>
            </v-card-title>
            <v-list two-line>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-certificate</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    DKK {{ commissionInformation.new / 100 | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Ny</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-hourglass-start</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    DKK {{ commissionInformation.pending / 100 | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Ongoing</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-clipboard-check</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    DKK {{ commissionInformation.ready / 100 | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Ready for payout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-sack-dollar</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    DKK {{ commissionInformation.legacy_unpaid | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Legacy Unpaid</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-chart-line</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <span :style="{color: commissionInformation.performance < -30 ? 'red' : 'green'}">
                      {{ commissionInformation.performance | local_numbers }} %
                    </span>
                  </v-list-item-title>
                  <v-list-item-title>Change in performance the last 30 days</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text small color="accent" :to="{ name: 'payouts' }">
                See payouts <v-icon color="accent" right>fal fa-angle-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex v-if="latestSales.length > 0" sm6 xs12>
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Latest sales</div>
              </div>
            </v-card-title>
            <v-data-table
              :headers="latestSalesHeader"
              :items="latestSales"
              hide-default-footer
              :items-per-page="10000"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="word-no-wrap">
                      {{ item.created_at | utc_to_local_datetime }}
                    </td>
                    <td>
                      <template v-if="item.click">
                        {{
                          timeDifference(item.click.created_at, item.created_at)
                        }}
                      </template>
                    </td>
                    <td>
                      <template v-if="item.click">
                        {{ item.click.campaign.business.display_name }}
                      </template>
                      <template v-else-if="item.promotion_code">
                        {{ item.promotion_code.business.display_name }}
                      </template>
                    </td>
                    <td>{{ item.commission | local_numbers }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                small
                color="accent"
                :to="{ name: 'report.sales' }"
              >
                See all sales <v-icon color="accent" right>fal fa-angle-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-flex class="pt-2" v-else>
      <v-row>
        <v-flex lg4 md6 xs12 class="p-2">
          <v-skeleton-loader
            class="rounded"
            height="100px"
            width="100%"
            type="image"
          ></v-skeleton-loader>
        </v-flex>
        <v-flex lg4 md6 xs12 class="p-2">
          <v-skeleton-loader
            class="rounded"
            height="100px"
            width="100%"
            type="image"
          ></v-skeleton-loader>
        </v-flex>
        <v-flex lg4 md6 xs12 class="p-2">
          <v-skeleton-loader
            class="rounded"
            height="100px"
            width="100%"
            type="image"
          ></v-skeleton-loader>
        </v-flex>
      </v-row>
      <v-row>
        <v-flex lg6 md6 xs12 class="p-2">
          <v-skeleton-loader
            class="rounded"
            height="100px"
            width="100%"
            type="image"
          ></v-skeleton-loader>
        </v-flex>
        <v-flex lg6 md6 xs12 class="p-2">
          <v-skeleton-loader
            class="rounded"
            height="100px"
            width="100%"
            type="image"
          ></v-skeleton-loader>
        </v-flex>
      </v-row>
      <v-row>
        <v-flex lg6 md6 xs12>
          <v-card class="p-2 m-2">
            <v-skeleton-loader
              class="rounded"
              type="article"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="rounded"
              width="100%"
              type="image"
            ></v-skeleton-loader>
          </v-card>
        </v-flex>
        <v-flex lg6 md6 xs12>
          <v-card class="p-2 m-2">
            <v-skeleton-loader
              class="rounded"
              type="article"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="rounded"
              width="100%"
              type="image"
            ></v-skeleton-loader>
          </v-card>
        </v-flex>
      </v-row>
    </v-flex>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import StatWidget from "../../widgets/StatWidget";
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import time_difference from "@/helpers/mixins/time_difference";

export default {
  components: { StatWidget },
  filters: { local_numbers, utc_to_local_datetime },
  mixins: [time_difference],
  data: () => ({
    currentDayData: {},
    hasInstagram: false,
    hasTiktok: false,
    tiktokChannel: {},
    instagramStats: {},
    commissionInformation: {},
    latestSales: [],
    latestSalesHeader: [
      {
        text: "Date",
        sortable: false
      },
      {
        text: "Conversion time",
        sortable: false
      },
      {
        text: "Brand",
        sortable: false
      },
      {
        text: "Commission",
        sortable: false
      }
    ],
    isLoading: true
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    ...mapActions("core", ["getStats", "getSales"]),
    ...mapActions("influencer", ["getInfluencerChannels", "getinfluencerCommission"]),
    ...mapActions("core", ["getInstagramReportSummerized"]),
    getChannels() {
      this.getInfluencerChannels({
        influencer_uuid: this.user.influencer.uuid,
      }).then((response) => {
        this.hasInstagram = response.some((item) => item.channel.name === 'instagram');
        this.tiktokChannel = response.find((item) => item.channel.name === 'tiktok');
        this.hasTiktok = !!this.tiktokChannel;
      });
    },
    _getInstagramStats() {
      let dateToday = this.$moment()
         .utc()
         .format("YYYY-MM-DD");

      let params = {
        posted_start_date: dateToday,
        posted_end_date: dateToday,
        has_campaign: true
      };

      this.getInstagramReportSummerized(params).then(response => {
        this.instagramStats = response;
      });
    },
    getTodayStats() {
      let params = {
        start_date: this.$moment()
          .utc()
          .format("YYYY-MM-DD"),
        end_date: this.$moment()
          .utc()
          .format("YYYY-MM-DD")
      };

      this.getStats(params).then(
        response => {
          this.currentDayData = response[0];
        },
        error => {
          console.log(error);
        }
      );
    },
    getCommissionInformation() {
      this.getinfluencerCommission().then(data => {
        this.commissionInformation = data;
      });
    },
    getLatestSales() {
      this.getSales({ limit: 5, page: 1 }).then(data => {
        this.latestSales = data.response;
        this.isLoading = false;
      });
    }
  },
  created() {
    this.getTodayStats();
    this.getChannels();
    this._getInstagramStats();
    this.getCommissionInformation();
    this.getLatestSales();
  }
};
</script>
